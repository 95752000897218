<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Performance da Região">
          <Performance
            :headers="headers"
            @getRelatorio="getRelatorio"
            :data="data"
            @getDownload="download"
            :canDownload="canDownload"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Performance from '@/components/relatorios/performance/Componente'
import RelatorioApi from '@/services/relatorios'
export default {
  provide() {
    return {
      tipo: 'regiao',
    }
  },
  data() {
    return {
      page: {
        title: 'Performance da Região',
      },
      breadcrumbs: [
        {
          text: 'Relatórios',
          disabled: true,
        },
        {
          text: 'Performance',
          disabled: false,
        },
        {
          text: 'Região',
          disabled: false,
        },
      ],
      headers: [
        { text: 'Data', value: 'data' },
        { text: 'Região', value: 'regiao' },
        { text: 'Deseginado', value: 'quantidade_designada' },
        { text: 'Agendado', value: 'quantidade_agendado' },
        { text: 'Atendido', value: 'quantidade_atendido' },
        { text: 'Saldo', value: 'quantidade_saldo' },
        { text: 'Performance (%)', value: 'quantidade_performance' },
      ],
      data: {},
      params: {},
      loading: false,
    }
  },
  components: { Performance },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    canDownload() {
      return Object.keys(this.params).length > 0
    },
  },
  methods: {
    load() {
      this.loading = true
    },
    loaded() {
      this.loading = false
    },
    async getRelatorio(params) {
      this.params = params
      this.load()
      const data = await RelatorioApi.getRelatorioPerformanceFornecedor({
        origem: this.empresaAtual.public_id,
        ...params,
      })
      this.data = data
      this.loaded()
    },
    async download() {
      await this.getDownload()
    },
    async getDownload() {
      if (!this.canDownload) return
      const uri = axios.getUri({
        url: 'api/v1/patio/programacoesFornecedor/relatorio-performance-excel/',
        params: {
          origem: this.empresaAtual.public_id,
          ...this.params,
        },
      })
      const base = axios.defaults.baseURL
      // adding slash if necessary
      const slash = base.slice(-1) == '/' ? '' : '/'
      const url = base + slash + uri
      window.open(url)
    },
  },
}
</script>

<style></style>
